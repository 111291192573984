<template>
  <div class="bg-blueGray-800 h-screen">
    <main>
      <div class="container relative mx-auto">
        <div class="title">
          <h1>CHECK-IN {{ errorMsg ? 'FAILED' : 'SUCCESSFUL'}}</h1>
        </div>
        <div class="user-detail-container">
          <table v-if="!errorMsg">
            <tr>
              <td><strong>Name</strong></td>
              <td>&nbsp;:&nbsp;{{ name }}</td>
            </tr>
            <tr>
              <td><strong>Company Name</strong></td>
              <td>&nbsp;:&nbsp;{{ companyName }}</td>
            </tr>
            <tr>
              <td><strong>Table Name</strong></td>
              <td>&nbsp;:&nbsp;{{ tableName }}</td>
            </tr>
          </table>
          <div v-else>
            <p>{{errorMsg}}</p>
          </div>
        </div>
        <div class="text-center mt-10">
          <router-link to="/check-in">
            <button
              class="bg-white text-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            >
              Scan another QR Code
            </button>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      id: this.$route.params.id,
      name: "",
      companyName: "",
      jobTitle: "",
      tableName: "",
      errorMsg: ""
    }
  },
  methods: {
    // call api to get
    async checkIn() {
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/check-in-guest-awards/" + this.id,
        );
        if (res.status === 200) {
          this.name = res.data.full_name;
          this.companyName = res.data.company_name;
          this.jobTitle = res.data.job_title;
          this.tableName = res.data.table_name;
        }
      } catch (error) {
        this.errorMsg = error.response.data.message || 'Something went wrong. Please try again.';
      }
    }
  },
  mounted() {
    this.checkIn();
  }
};
</script>

<style lang="sass" scoped>
main
  padding: 50px 20px
  .title
    text-align: center
    color: white
    padding-bottom: 30px
    h1
      font-size: 40px
      font-weight: bold
  .user-detail-container
    margin: auto
    max-width: 450px
    border-radius: 10px
    border: 5px solid white
    padding: 30px
    color: white

</style>